//= require jquery
//= require jquery.turbolinks
//= require jquery_ujs
//= require masonry/jquery.masonry
//= require turbolinks
//= require_self
//= require_tree .
//= require medium-editor

import Rails, { $ } from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// import "./standards/album"
// import "./standards/zoom2"
// import "./standards/masking_cpf_cnpj"
// import "./standards/validating_cpf" 
// import "./standards/validating_cnpj"
// import "./standards/turn"
// import "./standards/pdf-turn"
// import "./standards/scroll"
import "./standards/masking"
import "./standards/maskingex"
import "./standards/validating1"
import "./standards/common"
import "./standards/videos"
// import "./standards/carousel"
// Turbolinks.start()
// ActiveStorage.start()

/**
 * The project pattern on javascript files is listener -> function()
 * We will write the functions on the top and the listeners bellow, inside the jQuery tag
 * When we create a listener for click, load, focus, etc, we will not execute
 * the logic inside the listener event, we will call a function that will have a param
 * and will execute the logical part on itself. This is for organization purposes and 
 * readability.
 */

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener("DOMContentLoaded", function() {
  const application = Application.start()
  const controllers = require.context("controllers", true, /\.js$/)
  application.load(definitionsFromContext(controllers))

  const controllerElements = document.querySelectorAll("[data-controller]")
  controllerElements.forEach(element => {
    const controllerName = element.getAttribute("data-controller")
    const controller = application.getControllerForElementAndIdentifier(element, controllerName)
    if (controller) {
      new controller.constructor(element, controller.identifier)
    }
  })
})

// import { Controller } from "stimulus"

// export default class extends Controller {
//   static targets = ["destination"]
  
//   connect() {
//     this.lastScrollTop = 0
//     this.SCROLL_OFFSET = 80 // 5rem
//     this.handleScroll = this.handleScroll.bind(this)
//     window.addEventListener('scroll', this.handleScroll)
//     this.setupStyles()
//   }

//   setupStyles() {
//     const styles = `
//       #main-form, #main-form1, .formbottom, .formbottommobile {
//         transition: all 0.3s ease;
//       }
//     `
//     const styleSheet = document.createElement("style")
//     styleSheet.innerText = styles
//     document.head.appendChild(styleSheet)
//   }

//   disconnect() {
//     window.removeEventListener('scroll', this.handleScroll)
//   }

//   isOutOfViewport(element) {
//     const rect = element.getBoundingClientRect()
//     const header = document.querySelector('header')
//     const headerHeight = header ? header.offsetHeight : 0
    
//     // Verifica se passou do header + offset
//     const passedHeaderThreshold = window.pageYOffset > (headerHeight + this.SCROLL_OFFSET)
    
//     return rect.bottom < -(this.SCROLL_OFFSET) && passedHeaderThreshold
//   }

//   shouldReturnToTop() {
//     const header = document.querySelector('header')
//     const headerHeight = header ? header.offsetHeight : 0
    
//     // Retorna verdadeiro se voltou para próximo do topo
//     return window.pageYOffset < (headerHeight + this.SCROLL_OFFSET)
//   }

//   handleScroll() {
//     const isMobile = window.innerWidth <= 959
//     const mainForm = document.getElementById(isMobile ? 'main-form1' : 'main-form')
//     const formBottom = document.querySelector(isMobile ? '.formbottommobile' : '.formbottom')
//     const formTop = document.querySelector(isMobile ? '.formmobile' : '.formtop')
    
//     if (!mainForm || !formBottom || !formTop) return
    
//     const scrollTop = window.pageYOffset
//     const formBottomOffset = formBottom.offsetTop

//     // Lógica para mover para baixo
//     if (this.isOutOfViewport(mainForm)) {
//       if (scrollTop > this.lastScrollTop && scrollTop < formBottomOffset) {
//         requestAnimationFrame(() => formBottom.appendChild(mainForm))
//       }
//     }
    
//     // Lógica para retornar ao topo
//     if (this.shouldReturnToTop()) {
//       requestAnimationFrame(() => formTop.appendChild(mainForm))
//     }
    
//     this.lastScrollTop = scrollTop
//   }
// }

// import { Controller } from "stimulus";

// export default class extends Controller {
//   static targets = ["destination"];

//   connect() {
//     this.lastScrollTop = 0;
//     this.SCROLL_OFFSET = 80; // 5rem
//     this.handleScroll = this.handleScroll.bind(this);
//     window.addEventListener("scroll", this.handleScroll);
//     this.setupStyles();
//   }

//   setupStyles() {
//     const styles = `
//       #main-form, #main-form1, .formbottom, .formbottommobile {
//         transition: all 0.3s ease;
//       }
//     `;
//     const styleSheet = document.createElement("style");
//     styleSheet.innerText = styles;
//     document.head.appendChild(styleSheet);
//   }

//   disconnect() {
//     window.removeEventListener("scroll", this.handleScroll);
//   }

//   isOutOfViewport(element) {
//     const rect = element.getBoundingClientRect();
//     const header = document.querySelector("header");
//     const headerHeight = header ? header.offsetHeight : 0;
//     return rect.bottom < -this.SCROLL_OFFSET && window.pageYOffset > headerHeight + this.SCROLL_OFFSET;
//   }

//   shouldReturnToTop() {
//     const header = document.querySelector("header");
//     const headerHeight = header ? header.offsetHeight : 0;
//     return window.pageYOffset < headerHeight + this.SCROLL_OFFSET;
//   }

//   handleScroll() {
//     const isMobile = window.innerWidth <= 959;
//     const mainForm = document.getElementById(isMobile ? "main-form1" : "main-form");
//     const formBottom = document.querySelector(isMobile ? ".formbottommobile" : ".formbottom");
//     const formTop = document.querySelector(isMobile ? ".formmobile" : ".formtop");
    
//     if (!mainForm || !formBottom || !formTop) return;
    
//     const scrollTop = window.pageYOffset || document.scrollingElement.scrollTop;
//     const formBottomOffset = formBottom.offsetTop;

//     if (this.isOutOfViewport(mainForm)) {
//       if (scrollTop > this.lastScrollTop && scrollTop < formBottomOffset) {
//         requestAnimationFrame(() => formBottom.appendChild(mainForm));
//       }
//     }

//     if (this.shouldReturnToTop()) {
//       requestAnimationFrame(() => formTop.appendChild(mainForm));
//     }

//     this.lastScrollTop = scrollTop;
//   }
// }

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["destination"];

  connect() {
    this.lastScrollTop = 0;
    this.SCROLL_OFFSET_DOWN = 80; // 5rem
    this.SCROLL_OFFSET_UP = 70; // 2rem
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener("scroll", this.handleScroll);
    this.setupStyles();
  }

  setupStyles() {
    const styles = `
      #main-form, #main-form1, .formbottom, .formbottommobile {
        transition: opacity 0.2s ease, transform 0.3s ease;
      }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  isOutOfViewport(element) {
    const rect = element.getBoundingClientRect();
    const header = document.querySelector("header");
    const headerHeight = header ? header.offsetHeight : 0;
    return rect.bottom < -this.SCROLL_OFFSET_DOWN && window.pageYOffset > headerHeight + this.SCROLL_OFFSET_DOWN;
  }

  shouldReturnToTop() {
    const header = document.querySelector("header");
    const headerHeight = header ? header.offsetHeight : 0;
    return window.pageYOffset < headerHeight + this.SCROLL_OFFSET_UP;
  }

  handleScroll() {
    const isMobile = window.innerWidth <= 959;
    const mainForm = document.getElementById(isMobile ? "main-form1" : "main-form");
    const formBottom = document.querySelector(isMobile ? ".formbottommobile" : ".formbottom");
    const formTop = document.querySelector(isMobile ? ".formmobile" : ".formtop");
    
    if (!mainForm || !formBottom || !formTop) return;
    
    const scrollTop = window.pageYOffset || document.scrollingElement.scrollTop;
    const formBottomOffset = formBottom.offsetTop;

    if (this.isOutOfViewport(mainForm)) {
      if (scrollTop > this.lastScrollTop && scrollTop < formBottomOffset) {
        requestAnimationFrame(() => {
          mainForm.style.opacity = "0";
          setTimeout(() => {
            formBottom.appendChild(mainForm);
            mainForm.style.opacity = "1";
          }, 100);
        });
      }
    }

    if (this.shouldReturnToTop()) {
      requestAnimationFrame(() => {
        mainForm.style.opacity = "0";
        setTimeout(() => {
          formTop.appendChild(mainForm);
          mainForm.style.opacity = "1";
        }, 100);
      });
    }

    this.lastScrollTop = scrollTop;
  }
}
